
// Colors
$orange-gradient: linear-gradient(-90deg, #FAA545 0%, #F36C21 68%);
$orange-gradient-2: linear-gradient(to left bottom, #FAA545 40%, #F36C21 92%);
$orange: #ff7c25;
$orange-light: #FAA545;
$light: #f7f7f7;
$light-2: #cfcfcf;
$dark: #55565A;
$dark-2: #373f46;
$dark-3: #30373E;
$black: #333333;

$fuego-color: #D15438;
$haus-color: #EE9D26;
$renegade-color: #EEDC2A;

$atomic-color: #fd64ac;
$space-color: #FEE110;
$synergy-color: #2ed9c3;
$steve-color: #f0a440;
$cash-color: #6ba8ee;
$leadership-color: #a9cbf1;

@mixin text-gradient-orange {
	background: linear-gradient(-90deg, #FAA545 40%, #F36C21 92%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin setBadgeStyle($team-color) {
	border: 1px solid $team-color;
	border-color: $team-color;
	color: $team-color;
}

$shadow: 3px 2px 29px 2px rgba(0,0,0,0.5);
$shadow-sm: 0px 0px 20px 0px rgba(0,0,0,0.25);
$shadow-sm-2: 1px 0px 20px rgba(0,0,0,0.08);

// Fonts
$serif: 'LubalinGraphStd-Bold', Merriweather, Georgia, "Times New Roman", serif;
$sans-serif: 'BrandonText-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$header-height: 7rem;
$sidebar-width: 25rem;

$padding-sm: 1rem;
$padding-md: 2rem;
$padding-lg: 3rem;
$padding-xl: 5rem;

$margin-sm: 1rem;
$margin-md: 2rem;
$margin-lg: 3rem;
$margin-xl: 5rem;
