.login-container {
	background: $orange;
  	background-image: $orange-gradient;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	padding: 0 2rem;
}
.login-form {
	@media screen and (min-width: 769px) {
		width: 500px;
	}
}
.login-card {
	padding: 9rem;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	background: $dark-2;
	border-radius: 3px;

	input {
		width: auto;
	}

	@media screen and (max-width: 768px) {
		padding: 4rem;
	}

	&__logo {
		max-width: 80px;
		margin: 0 auto 2rem;
		@include respond-below(lg) {
			max-width: 55px;
		}
		img {
			width: 100%;
			height: auto;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 4rem;
		text-align: center;
	}
	&__title--small-spacing {
		margin-top: 0;
		margin-bottom: 1rem;
		text-align: center;
	}
	&__subtitle {
		text-align: center;
		margin-bottom: 3rem;
	}

	&__button {
		margin: 2rem 0;
	}

	&__error {
		text-align: center;
	}

	&__back {
		text-align: center;
	}
}