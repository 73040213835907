html {
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background-color: #30373E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@font-face {
  font-family: 'BrandonText-Regular';
  font-weight: normal;
  src: local("Brandon Text"), local("Brandon Text"), url(/static/media/BrandonText-Regular.cc4e72bd.otf) format("otf"), url(/static/media/BrandonText-Regular.bd9539ec.woff) format("woff"), url(/static/media/BrandonText-Regular.8c466100.woff2) format("woff2"); }

@font-face {
  font-family: 'BrandonText-Bold';
  font-weight: normal;
  src: local("Brandon Text"), local("Brandon Text"), url(/static/media/BrandonText-Bold.89fdab2e.otf) format("otf"), url(/static/media/BrandonText-Bold.e117d8dd.woff) format("woff"), url(/static/media/BrandonText-Bold.289ff13b.woff2) format("woff2"); }

@font-face {
  font-family: 'LubalinGraphStd-Bold';
  font-weight: normal;
  src: local("LubalinGraphStd-Bold"), local("LubalinGraphStd-Bold"), url(/static/media/LubalinGraphStd-Bold.d580364a.otf) format("otf"), url(/static/media/LubalinGraphStd-Bold.1ec3c556.woff) format("woff"), url(/static/media/LubalinGraphStd-Bold.2af8a115.woff2) format("woff2"); }

body {
  font-family: "BrandonText-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  color: #cfcfcf; }

h1, h2, h3, h4, h5, h6 {
  font-family: "LubalinGraphStd-Bold", Merriweather, Georgia, "Times New Roman", serif;
  font-weight: normal;
  font-style: normal;
  text-transform: lowercase;
  margin: 1.5rem 0; }

h1 {
  font-size: 4.6rem; }

h2 {
  font-size: 3.4rem; }

h3 {
  font-size: 2.6rem; }

h4 {
  font-size: 1.8rem; }

p {
  font-size: 1.8rem; }

@media (max-width: 989px) {
  h1 {
    font-size: 4rem; }
  h2 {
    font-size: 3rem; }
  h3 {
    font-size: 2.4rem; }
  h4 {
    font-size: 1.6rem; }
  p {
    font-size: 1.6rem; } }

a {
  text-decoration: none;
  color: #cfcfcf;
  cursor: pointer; }
  a:visited, a:hover, a:focus {
    text-decoration: none;
    color: #cfcfcf; }

.text-gradient-orange {
  background: -webkit-gradient(linear, right top, left top, color-stop(40%, #FAA545), color-stop(92%, #F36C21));
  background: -webkit-linear-gradient(right, #FAA545 40%, #F36C21 92%);
  background: linear-gradient(-90deg, #FAA545 40%, #F36C21 92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.orange-light {
  color: #FAA545; }

.btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 3px;
  padding: 1.5rem 2rem;
  font-family: "LubalinGraphStd-Bold", Merriweather, Georgia, "Times New Roman", serif;
  font-size: 1.8rem;
  text-transform: lowercase;
  cursor: pointer; }
  .btn-primary {
    background: -webkit-gradient(linear, right top, left top, from(#FAA545), color-stop(68%, #F36C21));
    background: -webkit-linear-gradient(right, #FAA545 0%, #F36C21 68%);
    background: linear-gradient(-90deg, #FAA545 0%, #F36C21 68%);
    color: #f7f7f7; }
  .btn-secondary {
    background: #f7f7f7;
    color: #ff7c25; }
  .btn-outline {
    background: transparent;
    border: 2px solid #ff7c25;
    color: #ff7c25; }

.btn-group button:not(:last-child) {
  margin-right: 1rem; }

input, select {
  box-sizing: border-box;
  display: block;
  font-size: 1.8rem;
  width: 100%;
  padding: 1rem 2rem;
  border: 1.5px solid #465059;
  background-color: transparent;
  color: #f7f7f7;
  border-radius: 3px;
  line-height: 1.2;
  -webkit-transition: color .3s ease-in-out,border-color .3s ease-in-out;
  transition: color .3s ease-in-out,border-color .3s ease-in-out; }
  input:focus, select:focus {
    outline: none;
    border-color: #ff7c25; }
  input::-webkit-input-placeholder, select::-webkit-input-placeholder {
    color: #cfcfcf;
    opacity: 0.5; }
  input::-moz-placeholder, select::-moz-placeholder {
    color: #cfcfcf;
    opacity: 0.5; }
  input::-ms-input-placeholder, select::-ms-input-placeholder {
    color: #cfcfcf;
    opacity: 0.5; }
  input::placeholder, select::placeholder {
    color: #cfcfcf;
    opacity: 0.5; }

select {
  width: 100%;
  color: #cfcfcf;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

@supports (-moz-appearance: none) {
  select {
    color: #cfcfcf;
    background: #55565A; } }

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */ }

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9; } }

label {
  font-size: 1.8rem; }

.input-w-icon-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative; }
  .input-w-icon-container__icon {
    position: absolute;
    right: 0;
    padding: 0 2rem;
    color: #cfcfcf;
    opacity: 0.5; }

.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .input-group__item-50 {
    flex-basis: 49%; }
    @media (max-width: 989px) {
      .input-group__item-50 {
        flex-basis: 100%; }
        .input-group__item-50:not(:last-child) {
          margin-bottom: 2rem; } }

input[type="file"] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px; }

.input-file-upload label {
  margin-top: 2rem; }

.input-file-upload__filename {
  margin-left: 2rem; }

body.w-sidebar-open .main .content {
  display: none; }

.app-container .main {
  display: flex;
  color: #cfcfcf; }
  .app-container .main .content {
    flex: 1 1;
    padding: 2rem;
    padding-top: 1rem;
    background-color: #30373E;
    position: relative;
    z-index: 0; }
    @media (min-width: 1200px) {
      .app-container .main .content {
        margin-left: 25rem; } }
    .app-container .main .content:before {
      content: '';
      background-color: #ff7c25;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150px;
      z-index: -1; }
    .app-container .main .content .page-title {
      color: #f7f7f7;
      margin-top: 2rem; }
      .app-container .main .content .page-title .goback {
        cursor: pointer; }
        .app-container .main .content .page-title .goback .goback-icon {
          font-size: 2.5rem;
          margin-right: 1rem; }
        .app-container .main .content .page-title .goback .goback-text {
          font-family: "LubalinGraphStd-Bold", Merriweather, Georgia, "Times New Roman", serif;
          font-size: 3rem; }

.header {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 0 3rem;
  width: 100%;
  height: 7rem;
  background: #ff7c25;
  background-image: -webkit-gradient(linear, right top, left top, from(#FAA545), color-stop(68%, #F36C21));
  background-image: -webkit-linear-gradient(right, #FAA545 0%, #F36C21 68%);
  background-image: linear-gradient(-90deg, #FAA545 0%, #F36C21 68%);
  color: #f7f7f7;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  z-index: 10; }
  .header__toggle {
    font-size: 2rem;
    color: #f7f7f7;
    margin-right: 3rem;
    z-index: 20; }
    @media (min-width: 1200px) {
      .header__toggle.hamburger {
        display: none; } }
  .header__admin {
    margin-left: auto; }
    .header__admin .btn {
      padding: 1rem 2rem; }
  .header__logo {
    max-width: 50px; }
    @media (max-width: 1199px) {
      .header__logo {
        max-width: 35px; } }
    .header__logo img {
      width: 100%;
      height: auto; }

.sidebar {
  width: 25rem;
  position: fixed;
  top: 7rem;
  height: 100vh;
  background: #373f46;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */ }
  .sidebar::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0; }
  @media (max-width: 1199px) {
    .sidebar {
      margin-left: -25rem; } }
  .sidebar__content {
    padding: 3rem; }
    .sidebar__content button {
      width: 200px;
      max-width: 100%; }
      @media (max-width: 767px) {
        .sidebar__content button {
          width: 100%; } }
  .sidebar__section {
    margin: 5rem 0; }
  .sidebar__section-title {
    margin-bottom: 2.2rem; }
  .sidebar__item {
    margin-bottom: 3.5rem;
    position: relative; }
    .sidebar__item a {
      font-size: 2rem;
      color: #cfcfcf; }
      .sidebar__item a:before {
        background: #ff7c25;
        position: absolute;
        width: 3px;
        height: 30px;
        left: -30px;
        top: 0;
        content: '';
        opacity: 0;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in; }
      .sidebar__item a.sidebar-link-is-active:before {
        opacity: 1; }
    .sidebar__item:hover a:before {
      opacity: 1; }
    .sidebar__item img {
      margin-right: 1rem;
      vertical-align: middle; }
    .sidebar__item.add-entry a {
      font-family: "LubalinGraphStd-Bold", Merriweather, Georgia, "Times New Roman", serif;
      font-size: 1.8rem;
      padding: 1rem 2rem;
      border-radius: 4px;
      background: #ff7c25;
      background-image: -webkit-linear-gradient(right, #FAA545 0%, #F36C21 68%);
      background-image: -webkit-gradient(linear, right top, left top, from(#FAA545), color-stop(68%, #F36C21));
      background-image: linear-gradient(-90deg, #FAA545 0%, #F36C21 68%);
      color: #ffffff; }
      .sidebar__item.add-entry a:before {
        background: none;
        position: absolute;
        width: 0;
        height: 0;
        left: -0;
        top: 0;
        content: ''; }
  .sidebar-open {
    width: 100%;
    height: auto;
    position: static;
    padding-bottom: 0;
    margin-left: 0; }

.entry-list__message {
  text-align: center;
  margin: 8rem 0; }

.entry-list .email-send a {
  font-family: "LubalinGraphStd-Bold", Merriweather, Georgia, "Times New Roman", serif;
  font-size: 1.8rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  background: #ff7c25;
  background-image: -webkit-linear-gradient(right, #FAA545 0%, #F36C21 68%);
  background-image: -webkit-gradient(linear, right top, left top, from(#FAA545), color-stop(68%, #F36C21));
  background-image: linear-gradient(-90deg, #FAA545 0%, #F36C21 68%);
  color: #ffffff; }

.entry-list-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #373f46;
  padding: 3rem;
  border-radius: 3px; }
  .entry-list-item:not(:last-child) {
    margin: 2rem 0; }
  .entry-list-item:last-child {
    margin-top: 2rem; }
  @media (max-width: 849px) {
    .entry-list-item {
      flex-direction: column;
      padding: 5rem 2rem; } }
  .entry-list-item__image {
    background-size: cover;
    width: 100px;
    height: 100px;
    border-radius: 200px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .entry-list-item__image {
        width: 100px; }
        .entry-list-item__image img {
          display: block;
          width: 100%;
          height: auto; } }
  .entry-list-item__person, .entry-list-item__room {
    flex: 1 0 350px;
    margin: 0 3rem; }
    @media (max-width: 849px) {
      .entry-list-item__person, .entry-list-item__room {
        flex-direction: column;
        flex: auto;
        margin: 2rem 0;
        text-align: center; } }
  .entry-list-item__name {
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0; }
    .entry-list-item__name h3 {
      margin: 0; }
    @media (max-width: 849px) {
      .entry-list-item__name {
        flex-direction: column; }
        .entry-list-item__name h3 {
          margin-bottom: 1rem; } }
  .entry-list-item__position {
    margin: 0;
    font-size: 1.4rem;
    letter-spacing: 1px; }
  .entry-list-item__badge {
    display: inline-block;
    margin-left: 1.25rem;
    margin-bottom: 0.75rem;
    padding: 0.2rem 1rem 0.5rem 1rem;
    border-radius: 3px; }
    @media (max-width: 849px) {
      .entry-list-item__badge {
        margin-left: 0; } }
    .entry-list-item__badge--fuego {
      border: 1px solid #D15438;
      border-color: #D15438;
      color: #D15438; }
    .entry-list-item__badge--haus {
      border: 1px solid #EE9D26;
      border-color: #EE9D26;
      color: #EE9D26; }
    .entry-list-item__badge--renegade {
      border: 1px solid #EEDC2A;
      border-color: #EEDC2A;
      color: #EEDC2A; }
    .entry-list-item__badge--atomic {
      border: 1px solid #fd64ac;
      border-color: #fd64ac;
      color: #fd64ac; }
    .entry-list-item__badge--space {
      border: 1px solid #FEE110;
      border-color: #FEE110;
      color: #FEE110; }
    .entry-list-item__badge--synergy {
      border: 1px solid #2ed9c3;
      border-color: #2ed9c3;
      color: #2ed9c3; }
    .entry-list-item__badge--steve {
      border: 1px solid #f0a440;
      border-color: #f0a440;
      color: #f0a440; }
    .entry-list-item__badge--cash {
      border: 1px solid #6ba8ee;
      border-color: #6ba8ee;
      color: #6ba8ee; }
    .entry-list-item__badge--leadership {
      border: 1px solid #a9cbf1;
      border-color: #a9cbf1;
      color: #a9cbf1; }
  .entry-list-item__extension h3, .entry-list-item__extension h3 a {
    color: #ff7c25;
    margin: 0;
    margin-top: 1rem; }
  .entry-list-item__control {
    margin-left: 2rem; }
    @media (max-width: 849px) {
      .entry-list-item__control {
        margin-left: 0;
        margin-top: 2rem; } }
    .entry-list-item__control span {
      cursor: pointer;
      color: #cfcfcf;
      opacity: 0.4;
      margin: 1rem; }

.entry-list-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #373f46;
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 3px; }
  .entry-list-filters__search {
    width: 50%; }
    @media (max-width: 989px) {
      .entry-list-filters__search {
        width: 100%; } }
  .entry-list-filters__sort {
    display: flex;
    width: 45%;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 989px) {
      .entry-list-filters__sort {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem; } }
    .entry-list-filters__sort label {
      flex: 0 0 85px; }
      @media (max-width: 989px) {
        .entry-list-filters__sort label {
          flex: auto;
          margin-bottom: 1rem;
          margin-left: 1rem; } }

.entry-form h3 {
  margin-top: 0;
  background: -webkit-gradient(linear, right top, left top, color-stop(40%, #FAA545), color-stop(92%, #F36C21));
  background: -webkit-linear-gradient(right, #FAA545 40%, #F36C21 92%);
  background: linear-gradient(-90deg, #FAA545 40%, #F36C21 92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.entry-form .input-group:not(:last-child) {
  margin: 2rem 0; }

.entry-form .input-group::last-child {
  margin-top: 2rem; }

.entry-form .input-group.phone-provider input {
  width: 48%; }

.entry-form__entry-type, .entry-form__room {
  border-radius: 3px;
  background-color: #373f46;
  padding: 3rem;
  margin-bottom: 2rem; }

.entry-form__person {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem; }

.entry-form .react-select-container .react-select__control {
  background-color: transparent;
  border-color: #465059;
  border-width: 1.5px;
  padding: 0.5rem 1rem;
  box-shadow: none; }

.entry-form .react-select-container .react-select__multi-value {
  padding: 2px; }
  .entry-form .react-select-container .react-select__multi-value__label {
    font-size: 14px; }
  .entry-form .react-select-container .react-select__multi-value__remove {
    color: #465059; }

.entry-form .react-select-container .react-select__indicator:hover {
  color: #cccccc; }

.entry-form .react-select-container .react-select__value-container {
  padding: 0; }

.entry-form .react-select-container .react-select__menu {
  background-color: #465059; }

.entry-form .react-select-container .react-select__option--is-focused {
  background-color: #373e44; }

.entry-form__general-info {
  flex-basis: 49.5%;
  border-radius: 3px;
  background-color: #373f46;
  padding: 3rem; }
  @media (max-width: 989px) {
    .entry-form__general-info {
      flex-basis: 100%;
      flex-grow: 1;
      margin-bottom: 2rem; } }

.entry-form__personal-info {
  flex-basis: 49%;
  border-radius: 3px;
  background-color: #373f46;
  padding: 3rem; }
  @media (max-width: 989px) {
    .entry-form__personal-info {
      flex-basis: 100%;
      flex-grow: 1; } }

.entry-form__photo {
  width: 49%;
  border-radius: 3px;
  background-color: #373f46;
  padding: 3rem;
  margin-bottom: 2rem; }
  @media (max-width: 1199px) {
    .entry-form__photo {
      width: 100%; } }
  .entry-form__photo h3 {
    margin-bottom: 3rem; }

@media (max-width: 989px) {
  .entry-form__submit-btn {
    width: 100%; } }

.entry-form__alerts {
  margin-top: 2rem; }

.entry-form__error, .entry-form__success {
  font-size: 1.8rem; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #f7f7f7; }

.hamburger-box {
  width: 25px;
  height: 17px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 25px;
    height: 3px;
    background-color: #f7f7f7;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -7px; }
  .hamburger-inner::after {
    bottom: -7px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  -webkit-perspective: 50px;
          perspective: 50px; }

.hamburger--3dx .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 50px;
          perspective: 50px; }

.hamburger--3dx-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  -webkit-perspective: 50px;
          perspective: 50px; }

.hamburger--3dy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 50px;
          perspective: 50px; }

.hamburger--3dy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 50px;
          perspective: 50px; }

.hamburger--3dxy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 50px;
          perspective: 50px; }

.hamburger--3dxy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
            transform: translate3d(0, -7px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-5px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-5px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-5px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-5px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(5px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(5px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(5px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(5px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-5px, -6.25px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-5px, -6.25px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-5px, 6.25px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-5px, 6.25px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(5px, -6.25px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(5px, -6.25px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(5px, 6.25px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(5px, 6.25px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  -webkit-transition-property: none;
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -14px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
          transform: translate3d(0, -7px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -14px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -7px, 0) rotate(45deg);
          transform: translate3d(0, -7px, 0) rotate(45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 7px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 14px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
          transform: translate3d(0, 7px, 0) rotate(135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(-270deg);
            transform: translate3d(0, -14px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 7px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 14px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(-135deg);
          transform: translate3d(0, 7px, 0) rotate(-135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(270deg);
            transform: translate3d(0, -14px, 0) rotate(270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    -webkit-transition: background-color 0.125s 0.175s ease-in;
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic .hamburger-inner::after {
      top: 7px;
      right: 0;
      -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic.is-active .hamburger-inner {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -50px;
      top: -50px;
      -webkit-transform: translate3d(50px, 50px, 0) rotate(45deg);
              transform: translate3d(50px, 50px, 0) rotate(45deg);
      -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -50px;
      top: -50px;
      -webkit-transform: translate3d(-50px, 50px, 0) rotate(-45deg);
              transform: translate3d(-50px, 50px, 0) rotate(-45deg);
      -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    -webkit-transition: background-color 0.125s 0.175s ease-in;
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 7px;
      right: 0;
      -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -50px;
      top: 50px;
      -webkit-transform: translate3d(50px, -50px, 0) rotate(-45deg);
              transform: translate3d(50px, -50px, 0) rotate(-45deg);
      -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -50px;
      top: 50px;
      -webkit-transform: translate3d(-50px, -50px, 0) rotate(45deg);
              transform: translate3d(-50px, -50px, 0) rotate(45deg);
      -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  -webkit-transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  -webkit-transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider .hamburger-inner::before {
    top: 7px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 14px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
          transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-3.57143px, -4px, 0);
            transform: rotate(-45deg) translate3d(-3.57143px, -4px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(-90deg);
            transform: translate3d(0, -14px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 7px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 14px; }

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 7px, 0) rotate(-45deg);
          transform: translate3d(0, 7px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    -webkit-transform: rotate(45deg) translate3d(3.57143px, -4px, 0);
            transform: rotate(45deg) translate3d(3.57143px, -4px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -14px, 0) rotate(90deg);
            transform: translate3d(0, -14px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  -webkit-transition: background-color 0s 0.13s linear;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 7px;
    -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 14px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
            transform: translate3d(0, 7px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 7px, 0) rotate(-45deg);
            transform: translate3d(0, 7px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -14px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
          transform: translate3d(0, -7px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent !important;
  -webkit-transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-color: transparent !important;
  -webkit-transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  -webkit-transition-duration: 0.075s;
          transition-duration: 0.075s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
          transform: rotate(765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
          transform: rotate(-765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.login-container {
  background: #ff7c25;
  background-image: -webkit-gradient(linear, right top, left top, from(#FAA545), color-stop(68%, #F36C21));
  background-image: -webkit-linear-gradient(right, #FAA545 0%, #F36C21 68%);
  background-image: linear-gradient(-90deg, #FAA545 0%, #F36C21 68%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 2rem; }

@media screen and (min-width: 769px) {
  .login-form {
    width: 500px; } }

.login-card {
  padding: 9rem;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background: #373f46;
  border-radius: 3px; }
  .login-card input {
    width: auto; }
  @media screen and (max-width: 768px) {
    .login-card {
      padding: 4rem; } }
  .login-card__logo {
    max-width: 80px;
    margin: 0 auto 2rem; }
    @media (max-width: 1199px) {
      .login-card__logo {
        max-width: 55px; } }
    .login-card__logo img {
      width: 100%;
      height: auto; }
  .login-card__title {
    margin-top: 0;
    margin-bottom: 4rem;
    text-align: center; }
  .login-card__title--small-spacing {
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center; }
  .login-card__subtitle {
    text-align: center;
    margin-bottom: 3rem; }
  .login-card__button {
    margin: 2rem 0; }
  .login-card__error {
    text-align: center; }
  .login-card__back {
    text-align: center; }

.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh; }
  .loading-screen__loader img {
    height: 50px;
    width: 50px; }

.entry-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem; }
  .entry-details h3 {
    margin-top: 0;
    background: -webkit-gradient(linear, right top, left top, color-stop(40%, #FAA545), color-stop(92%, #F36C21));
    background: -webkit-linear-gradient(right, #FAA545 40%, #F36C21 92%);
    background: linear-gradient(-90deg, #FAA545 40%, #F36C21 92%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .entry-details__card {
    display: flex;
    flex-basis: 35%;
    flex-direction: column;
    align-items: center;
    background-color: #373f46;
    padding: 3rem 2rem;
    border-radius: 3px; }
    @media (max-width: 989px) {
      .entry-details__card {
        flex-basis: 100%;
        margin-bottom: 2rem; } }
  .entry-details__person, .entry-details__room {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
  .entry-details__image {
    background-size: cover;
    width: 200px;
    height: 200px;
    border-radius: 200px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .entry-details__image {
        width: 200px; }
        .entry-details__image img {
          display: block;
          width: 100%;
          height: auto; } }
  .entry-details__control span {
    cursor: pointer;
    color: #cfcfcf;
    opacity: 0.4;
    margin: 1rem; }
  .entry-details__name {
    margin: 0;
    margin-top: 3rem; }
  .entry-details__badge-wrapper {
    margin-bottom: 2rem; }
    .entry-details__badge-wrapper > a:not(:last-child) {
      margin-right: 1rem; }
  .entry-details__badge {
    display: inline-block;
    padding: 0.2rem 1rem 0.5rem 1rem;
    border-radius: 3px; }
    .entry-details__badge--fuego {
      border: 1px solid #D15438;
      border-color: #D15438;
      color: #D15438; }
    .entry-details__badge--haus {
      border: 1px solid #EE9D26;
      border-color: #EE9D26;
      color: #EE9D26; }
    .entry-details__badge--renegade {
      border: 1px solid #EEDC2A;
      border-color: #EEDC2A;
      color: #EEDC2A; }
    .entry-details__badge--atomic {
      border: 1px solid #fd64ac;
      border-color: #fd64ac;
      color: #fd64ac; }
    .entry-details__badge--space {
      border: 1px solid #FEE110;
      border-color: #FEE110;
      color: #FEE110; }
    .entry-details__badge--synergy {
      border: 1px solid #2ed9c3;
      border-color: #2ed9c3;
      color: #2ed9c3; }
    .entry-details__badge--steve {
      border: 1px solid #f0a440;
      border-color: #f0a440;
      color: #f0a440; }
    .entry-details__badge--cash {
      border: 1px solid #6ba8ee;
      border-color: #6ba8ee;
      color: #6ba8ee; }
    .entry-details__badge--leadership {
      border: 1px solid #a9cbf1;
      border-color: #a9cbf1;
      color: #a9cbf1; }
  .entry-details__position {
    font-size: 1.4rem;
    letter-spacing: 1px;
    margin-top: 0;
    margin-bottom: 2rem; }
  .entry-details__info {
    flex-basis: 63.5%; }
    @media (max-width: 989px) {
      .entry-details__info {
        flex-basis: 100%;
        text-align: center; } }
  .entry-details__general-info, .entry-details__personal-info {
    background-color: #373f46;
    padding: 3rem;
    border-radius: 3px; }
  .entry-details__general-info {
    margin-bottom: 2rem; }
  .entry-details .entry-field:not(:last-child) {
    margin-bottom: 2rem; }
  .entry-details .entry-field__title {
    margin: 0;
    margin-bottom: 0.5rem; }
  .entry-details .entry-field__content {
    margin: 0; }
  .entry-details .entry-field.phone-provider div {
    width: 50%;
    display: inline-block; }

.page-not-found {
  position: relative;
  flex: 1 1;
  background-color: #30373E;
  position: relative;
  z-index: 0; }
  @media (min-width: 1200px) {
    .page-not-found {
      margin-left: 25rem; } }
  .page-not-found__bg {
    background-image: url(/static/media/404.7c8f67fa.gif);
    background-size: cover;
    background-position: 50%;
    opacity: .3;
    height: 100vh;
    z-index: 0; }
  .page-not-found__message {
    position: absolute;
    top: 40%;
    left: 50%;
    text-align: center;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform: translatex(-50%);
            transform: translatex(-50%);
    background: transparent;
    z-index: 1; }
    .page-not-found__message h2 {
      color: white; }

