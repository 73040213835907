.entry-list {

	&__message {
		text-align: center;
		margin: 8rem 0;
 }
 
 .email-send a {
  font-family: "LubalinGraphStd-Bold", Merriweather, Georgia, "Times New Roman", serif;
  font-size: 1.8rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  background: #ff7c25;
  background-image: -webkit-linear-gradient(right, #FAA545 0%, #F36C21 68%);
  background-image: linear-gradient(
-90deg
, #FAA545 0%, #F36C21 68%);
  color: #ffffff;
 }

}