.entry-list-filters {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: $dark-2;
	padding: $padding-md;
	margin: $margin-md 0;
	border-radius: 3px;

	&__search {
		width: 50%;
		@include respond-below(md) {
			width: 100%;
		}
	}

	&__sort {
		display: flex;
		width: 45%;;
		align-items: center;
		justify-content: space-between;

		@include respond-below(md) {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			margin-top: 2rem;
		}

		label {
			flex: 0 0 85px;
			@include respond-below(md) {
				flex: auto;
				margin-bottom: 1rem;
				margin-left: 1rem;
			}
		}
	}
}