.header {
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	// left: $sidebar-width;
	padding: 0 $padding-lg;
	width: 100%;
	height: $header-height;
	background: $orange;
	background-image: $orange-gradient;
	color: $light;
	box-shadow: $shadow-sm;
	z-index: 10;

	&__toggle {
		font-size: 2rem; 
		color: $light;
		margin-right: $margin-lg;
		z-index: 20;
	
		@include respond-above(lg) {
			&.hamburger {
				display: none;
			}
		}
	}

	&__admin {
		margin-left: auto;
		.btn {
			padding: 1rem 2rem;
		}
	}

	&__logo {
		max-width: 50px;
		@include respond-below(lg) {
			max-width: 35px;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}