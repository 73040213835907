.page-not-found {
	position: relative;
	flex: 1;
	background-color: $dark-3;
	position: relative;
	z-index: 0;

	@include respond-above(lg) {
	  margin-left: $sidebar-width;
	}

	&__bg{
		background-image: url(../images/404.gif);
		background-size: cover;
		background-position: 50%;
		opacity: .3;
		height: 100vh;
		z-index: 0;
	}

	&__message {
		position: absolute;
		top: 40%;
		left: 50%;
		text-align: center;
		transform: translateY(-50%);
		transform: translatex(-50%);
		background: transparent;
		z-index: 1;
		h2 {
			color: white;
		}
	}
}