html {
	font-size: 62.5%;
	box-sizing:border-box;
	height: 100%;
}
  
*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	background-color: $dark-3;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}